import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiGetAllChats, Chat, ParticipantDetails } from '../api/chat';
import { toast } from 'react-toastify';
import { GridLoader } from 'react-spinners';
import { AuthContext } from '../contexts/AuthContext';
import { auth } from '../firebase';


const ViewChats: React.FC = () => {
  const [chats, setChats] = useState<Chat[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const context = useContext(AuthContext);

  const userId = auth.currentUser?.uid;

  useEffect(() => {    
    const fetchChats = async () => {
        try {
            const res = await apiGetAllChats();

            setChats(res.data.chats);
        } catch (error) {
            toast.error('Failed to fetch chats');
        }
        setLoading(false);
    };

    fetchChats();
  }, []);

  const getChatName = (chat: Chat) => {
    if (chat.participantsDetails.length === 2) {
        const otherUser = chat.participantsDetails.find((p) => p.id !== userId);
        return otherUser ? otherUser.fullname : chat.name;
    }

    return chat.name;
  };
  const getOtherUser = (chat: Chat): ParticipantDetails | null => {
    if (chat.participantsDetails.length !== 2) return null;
    
    return chat.participantsDetails.find((p) => p.id !== userId) as ParticipantDetails;
  }

  if (loading  || !context) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <GridLoader/>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="flex flex-row justify-end">
        <div>
          <button
              onClick={context.logout}
              className="text-black p-2 rounded bg-red-400 hover:bg-red-600 transition-all"
            >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6">
              <path fillRule="evenodd" d="M16.5 3.75a1.5 1.5 0 0 1 1.5 1.5v13.5a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5V15a.75.75 0 0 0-1.5 0v3.75a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3V9A.75.75 0 1 0 9 9V5.25a1.5 1.5 0 0 1 1.5-1.5h6Zm-5.03 4.72a.75.75 0 0 0 0 1.06l1.72 1.72H2.25a.75.75 0 0 0 0 1.5h10.94l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 0 0-1.06 0Z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      <div className="max-w-4xl mx-auto bg-white p-8 rounded shadow">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Þín spjöll</h2>
        {chats.length > 0 ? (
          <ul className="space-y-4">
            {chats?.map((chat) => (
              <li
                key={chat.id}
                className="flex items-center justify-between p-4 bg-gray-50 hover:bg-gray-100 rounded-lg border border-gray-200 shadow-sm transition-all"
              >
                <div>
                  <Link
                    to={`/chat/${chat.id}`}
                    className="text-lg text-blue-600 hover:underline font-semibold"
                  >
                    {getChatName(chat)}
                  </Link>
                  {getOtherUser(chat) && <p className="text-sm text-gray-600">forgangur: {getOtherUser(chat)?.urgency}</p>}
                  {chat.participantsDetails.length > 2 && (
                    <p className="text-sm text-gray-600">
                      {chat.participantsDetails.map((o) => o.fullname).join(', ')}
                    </p>
                  )}
                </div>
                <Link
                  to={`/chat/${chat.id}`}
                  className="text-sm text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md transition-all"
                >
                  Skoða spjall
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center text-gray-500">Engin spjöll fundust</p>
        )}
      </div>
    </div>
  );
};

export default ViewChats;
