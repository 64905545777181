import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiGetCurrentUser, apiRegister, RegisterResponse, User } from '../api/auth';
import { browserLocalPersistence, onAuthStateChanged, setPersistence, signInWithEmailAndPassword, signOut } from "firebase/auth";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { auth } from '../firebase';
import { usePrevious } from '../hooks/usePrevious';

export type AuthContextType = {
  user: User | null;
  userLoading: boolean;
  login: (email: string, password: string) => Promise<User | undefined | "invalid-credentials">;
  register: (email: string, password: string, fullname: string, role: string, ssn: string) => Promise<RegisterResponse | undefined>;
  logout: () => Promise<void>;
  isAdmin: boolean;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const isAdmin = user?.role === 'admin';

  const location = useLocation();
  const prevUser = usePrevious(user);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        loadUser();
      } else {
        setUserLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const loadUser = async () => {
    setUserLoading(true);

    try {
      const res = await apiGetCurrentUser();
      setUser(res.data.user);
    } catch (error) {
      setUser(null);
    } finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (!prevUser && user && ['/login', '/'].includes(location.pathname)) {
      navigate("/home");
    }
  }, [user, location.pathname]);

  const login = async (email: string, password: string): Promise<User | undefined | "invalid-credentials"> => {
    try {
      await setPersistence(auth, browserLocalPersistence);

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const idToken = await user.getIdToken();

      const userFull = (await apiGetCurrentUser()).data.user;

      setUser(userFull);
      navigate('/home');

      return userFull;

    } catch (error: any) {
      console.error('Error signing in:', error);

      if (error?.code === "auth/invalid-credential"){
        return "invalid-credentials"
      }
      return undefined;
    }
  };

  const register = async (email: string, password: string, fullname: string, role: string, ssn: string): Promise<RegisterResponse | undefined> => {
    try {
      const res = await apiRegister(email, password, fullname, role, ssn);
      if (res.data.success) {
        navigate('/login');
        toast.success('Notandi stofnaður');
      } else {
        toast.error(res.data.message);
      }
      return res;
    } catch (error: any) {
      alert(error.response?.data?.message || 'Registration failed');
      return undefined;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      Cookies.remove('access_token');
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, userLoading, login, register, logout, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};
