import React from 'react';
import { LogoutBar } from '../components/LogoutBar'; // Import the LogoutBar component

const Header: React.FC = () => {
  return (
    <header className="w-full bg-gray-900 text-white shadow-xl">
      <div className="max-w-6xl mx-auto px-6 py-3 flex items-center justify-between">
        {/* Left Section - Logo and Title */}
        <div className="flex items-center space-x-4">
          <img
            src="/testlogo.png"
            alt="Logo"
            className="w-12 h-12 object-contain hover:opacity-90 transition-opacity duration-300 transform hover:scale-110"
          />
          <h1 className="text-2xl font-bold tracking-tight hover:text-teal-400 transition-colors duration-300">
            Urgent Chats Dashboard
          </h1>
        </div>
        
        {/* Right Section - Logout and Additional Links */}
        <div className="flex items-center space-x-8">
          <a href="#" className="text-teal-400 hover:text-teal-200 transition-colors duration-300">
            Support
          </a>
          <a href="#" className="text-teal-400 hover:text-teal-200 transition-colors duration-300">
            Settings
          </a>
          <LogoutBar />
        </div>
      </div>

      {/* Animated Border */}
      <div className="w-full h-1 bg-gradient-to-r from-blue-500 to-teal-400 animate-pulse"></div>
    </header>
  );
};

export default Header;
