import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import AccountDropdown from '../components/AccountDropdown';

interface NavbarProps {
  isSidebarCollapsed: boolean;
  toggleSidebar: () => void;
}

const Navbar: React.FC<NavbarProps> = ({isSidebarCollapsed, toggleSidebar}) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <nav className="bg-gradient-to-r from-blue-500 via-green-500 to-purple-500 p-4 flex justify-between items-center shadow-md moving-gradient">
      <div className="flex items-center px-4">
        {isSidebarCollapsed && (
          <>
            <button
              onClick={toggleSidebar}
              className="text-white hover:bg-blue-300 p-2 rounded-full transition-colors duration-300 mr-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
            <button 
              className="text-white hover:bg-blue-300 p-2 rounded-full transition-colors duration-300 mr-2"
              onClick={() => navigate("/chat")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button>
          </>
        )}
        <img src="/testlogo.png" alt="Logo" className="h-10 w-10 mr-3" />
        <h1 className="text-white text-2xl font-bold">Ása</h1>
      </div>
      {auth && auth.user && (
        <div className="flex items-center">
          <div className="flex items-center mr-4 text-white font-semibold">
            <span>Hæ :) {auth.user.displayName || auth.user.fullname}</span>
            <AccountDropdown onLogout={auth.logout} />
          </div>
          {auth.isAdmin && (
            <Link to="/create-doctor" className="text-white mr-4 hover:underline">
              Create Doctor
            </Link>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
