import {AxiosResponse} from 'axios';
import {api, getAuthorization} from "./index"

interface DefaultResponse {
    success: boolean,
    message: string
}

export interface User {
    id: string,
    email: string,
    displayName: string | null,
    photoURL: string | null,
    role: string,
    fullname: string
    ssn: string;
}

export type RegisterResponse = AxiosResponse<DefaultResponse>

export type GetUserResponse = AxiosResponse<{user: User}>


export const apiRegister = (email: string, password: string, fullname: string, role: string, ssn: string): Promise<RegisterResponse> =>
  api.post('/api/auth/register', { email, password, fullname, role, ssn });

export const apiGetCurrentUser = async (): Promise<GetUserResponse> => 
  await api.get('/api/auth/user', { headers: (await getAuthorization()) })