import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import Chat from './pages/Chat';
import DoctorList from './pages/DoctorsList';
import CreateDoctor from './pages/CreateDoctor';
import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext'; // Import LanguageProvider
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import ViewChats from './pages/ViewChats';
import ViewChat from './pages/ViewChat';
import UrgentChatsList from './pages/UrgentChatsList';

const App: React.FC = () => {
  return (
    <Router>
      <LanguageProvider>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />

            <Route
              path="/chat/:chatId?"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-chats"
              element={
                <ProtectedRoute>
                  <ViewChats />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-urgent-chats"
              element={
                <ProtectedRoute>
                  <UrgentChatsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-doctor"
              element={
                <AdminRoute>
                  <CreateDoctor />
                </AdminRoute>
              }
            />
            <Route
              path="/doctor-list"
              element={
                <AdminRoute>
                  <DoctorList />
                </AdminRoute>
              }
            />
            <Route path="*" element={<Login />} />
          </Routes>
        </AuthProvider>
      </LanguageProvider>
    </Router>
  );
};

export default App;
