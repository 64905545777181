import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import ChatMessage from '../components/ChatMessage';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { apiSendChat, apiCreateAsaChat, apiDeleteAsaChat, apiGetAllAsaChats, apiGetAsaChatMessages, RestrictedAsaChat } from '../api/asaChat';
import { AsaChatMessage } from '../api/asaChat';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<AsaChatMessage[]>([]);
  const [input, setInput] = useState('');
  const [chats, setChats] = useState<RestrictedAsaChat[]>([]);
  const [isNextChatLoading, setIsNextChatLoading] = useState<boolean>(false);
  const [currentChatId, setCurrentChatId] = useState<string | null>(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const auth = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();

  // Load all chats on component mount
  useEffect(() => {
    const loadChats = async () => {
      try {
        const res = await apiGetAllAsaChats();
        setChats(res.data.chats);
      } catch (error) {
        console.error('Failed to load chats:', error);
      }
    };

    loadChats();
  }, []);

  useEffect(() => {
    setCurrentChatId(params.chatId ? params.chatId : null)
  }, [params])

  // Load chat messages when a chat is selected
  useEffect(() => {
    if (currentChatId) {
      const loadMessages = async () => {
        try {
          const res = await apiGetAsaChatMessages(currentChatId);
          setMessages(res.data.messages);
        } catch (error) {
          console.error('Failed to load chat messages:', error);
        }
      };

      loadMessages();
    }else{
      setMessages([]);
    }
  }, [currentChatId]);

  // Handle sending a message
  const handleSend = async () => {
    if (!input.trim() || !currentChatId) return;

    const newMessage = { role: 'user' as const, content: input, timestamp: serverTimestamp() as Timestamp };
    setInput('');
    setMessages((prev) => [...prev, newMessage]);
    setIsNextChatLoading(true);

    try {
      const res = await apiSendChat(currentChatId, [...messages, newMessage]);
      const botMessage = { role: 'assistant' as const, content: res.data.message, timestamp: serverTimestamp() as Timestamp };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
    setIsNextChatLoading(false);
  };

  // Create a new chat
  const handleCreateChat = async () => {
    try {
      if(!auth || !auth.user) throw new Error('auth error');

      const res = await apiCreateAsaChat();
      navigate(`/chat/${res.data.chatId}`);
      setMessages([]); // Reset messages when starting a new chat
      setChats((prev) => [...prev, { id: res.data.chatId, userId: auth.user?.id || '', diagnosis: '', urgency: 1, createdAt: new Date() as any, updatedAt: new Date() as any }]);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  // Delete a chat
  const handleDeleteChat = async (chatId: string) => {
    try {
      await apiDeleteAsaChat(chatId);
      setChats((prev) => prev.filter((chat) => chat.id !== chatId));
      if (currentChatId === chatId) {
        setCurrentChatId(null);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(collapsed => !collapsed);
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-r from-blue-100 via-white to-blue-100 text-black">
      <Sidebar chats={chats} onSelectChat={(id) => navigate(`/chat/${id}`)} onDeleteChat={handleDeleteChat} isCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />
      <div className="flex flex-col flex-1 transition-all duration-300">
        <Navbar isSidebarCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />
        <div className="flex-1 flex flex-col items-center justify-start relative mt-12">
          {!currentChatId && (
            <div className="flex flex-col items-center gap-10 w-full max-w-4xl justify-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 px-8 w-full max-w-4xl justify-center">
                <div className="bg-blue-200 p-8 rounded-lg shadow-md text-center w-full h-40 flex items-center justify-center transform hover:scale-105 transition-transform">
                  <div>
                    <p className="text-3xl text-blue-600 mb-4">🕒</p>
                    <p className="font-bold text-blue-700">Ása er til staðar 24/7</p>
                    <p className="text-blue-800">Alltaf til staðar, dag og nótt. Fáðu ráðgjöf eða aðstoð hvenær sem er.</p>
                  </div>
                </div>
                <div className="bg-green-200 p-8 rounded-lg shadow-md text-center w-full h-40 flex items-center justify-center transform hover:scale-105 transition-transform">
                  <div>
                    <p className="text-3xl text-green-600 mb-4">💬</p>
                    <p className="font-bold text-green-700">Ráðgjöf frá Ásu</p>
                    <p className="text-green-800">Fáðu ráðgjöf frá Ásu og sérfræðingum þegar þú þarft á því að halda.</p>
                  </div>
                </div>
                <div className="bg-yellow-200 p-8 rounded-lg shadow-md text-center w-full h-40 flex items-center justify-center transform hover:scale-105 transition-transform">
                  <div>
                    <p className="text-3xl text-yellow-600 mb-4">🩺</p>
                    <p className="font-bold text-yellow-700">Sjúkdómagreining</p>
                    <p className="text-yellow-800">Ása getur greint sjúkdóma og vísað þér til réttra sérfræðinga.</p>
                  </div>
                </div>
                <div className="bg-red-200 p-8 rounded-lg shadow-md text-center w-full h-40 flex items-center justify-center transform hover:scale-105 transition-transform">
                  <div>
                    <p className="text-3xl text-red-600 mb-4">📅</p>
                    <p className="font-bold text-red-700">Bókaðu tíma</p>
                    <p className="text-red-800">Ása getur bókað tíma hjá sérfræðingum fyrir þig á einfaldan hátt.</p>
                  </div>
                </div>
              </div>

              <button
              onClick={handleCreateChat}
              className="bg-gradient-to-r from-purple-600 to-blue-600 text-white font-bold text-base px-6 py-3 rounded-xl shadow-lg flex items-center justify-center space-x-2 transform hover:scale-105 transition duration-300 ease-in-out hover:from-purple-700 hover:to-blue-700"
              >
              <span>Hefja nýtt spjall</span> {/* Text */}
              <span className="ml-2">→</span> {/* Right Arrow */}
              </button>



            </div>
          )}

          {/* Chat Messages */}
          <div className="flex-1 p-6 overflow-auto w-full z-20">
            {messages.map((msg, index) => (
              <ChatMessage key={index} role={msg.role} content={formatTextToJSX(msg.content)} />
            ))}
            {isNextChatLoading && <ChatMessage key="loading" role="assistant" content={<PulseLoader className='p-2'/>} />}
          </div>
        </div>

        {/* Chat Input */}
        {currentChatId && (
          <div className="p-4 bg-white flex items-center shadow-md z-20">
            <input
              type="text"
              className="flex-1 border rounded-full px-4 py-2 text-gray-800 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
              placeholder="Spjalla við Ásu"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              onClick={handleSend}
              className="ml-4 bg-blue-600 text-white bold px-4 py-2 rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
            >
              Senda
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

function formatTextToJSX(text: string) {
  // Split the text by newlines
  const lines = text.split('\n');

  // Create an array to hold the formatted JSX elements
  const elements = [];

  let listItems: JSX.Element[] = [];
  let insideList = false;

  lines.forEach((line, index) => {
    // Trim the line to remove leading and trailing whitespace
    const trimmedLine = line.trim();

    // Check if the line starts with a numbered list pattern (e.g., "1. text")
    const listItemMatch = trimmedLine.match(/^\d+\.\s+(.*)/);

    if (listItemMatch) {
      // If it's a numbered list item, push it to the listItems array
      listItems.push(<li key={`li-${index}`}>{listItemMatch[1]}</li>);
      insideList = true;
    } else {
      // If we were inside a list and now encounter a non-list line, close the list
      if (insideList) {
        elements.push(<ol key={`ol-${index}`} className="list-decimal ml-5">{listItems}</ol>);
        listItems = [];
        insideList = false;
      }

      // Push the current line as a regular text element with a <br /> for newlines
      elements.push(
        <React.Fragment key={`line-${index}`}>
          {trimmedLine}
          <br />
        </React.Fragment>
      );
    }
  });

  // If the text ends with a list, make sure to close it
  if (insideList) {
    elements.push(<ol key={`ol-end`} className="list-decimal ml-5">{listItems}</ol>);
  }

  return <div>{elements}</div>;
}

export default Chat;
