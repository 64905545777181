import React from 'react';

interface ChatMessageProps {
  role: string;
  content: string | JSX.Element;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ role, content }) => {
  const isUser = role === 'user';
  
  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
      <div
        className={`max-w-xs px-4 py-2 rounded-lg ${
          isUser 
            ? 'bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white' 
            : 'bg-blue-100 text-gray-900'
        } shadow-lg`}
      >
        {content}
      </div>
    </div>
  );
};

export default ChatMessage;
