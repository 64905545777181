import {AxiosResponse} from 'axios';
import {api, getAuthorization} from "./index";

export interface Chat {
    id: string,
    name: string,
    participants: string[],
    participantsDetails: {
        id: string,
        email: string,
        fullname: string,
        role: string
    }[]
} 
export type GetAllChatsResponse = AxiosResponse<{chats: Chat[]}>

export interface Message {
    content: string,
    readBy: string[],
    senderId: string,
    timestamp: number
}
export type GetChatMessagesResponse = AxiosResponse<{messages: Message[]}>

export interface ChatDetails {
    name: string,
    participants: string[],
    participantsDetails: ParticipantDetails[]
}
export interface ParticipantDetails{
    id: string,
    email: string,
    fullname: string,
    role: string,
    diagnosis?: string,
    urgency?: string
}

export type GetChatDetailsResponse = AxiosResponse<ChatDetails>

export const apiGetAllChats = async (): Promise<GetAllChatsResponse> => 
    await api.get('/api/chat/list', { headers: await getAuthorization() });

export const apiGetChatMessages = async (chatId: string): Promise<GetChatMessagesResponse> => 
    await api.get(`/api/chat/${chatId}/messages`, { headers: await getAuthorization() })

export const apiGetChatDetails = async (chatId: string): Promise<GetChatDetailsResponse> => 
    await api.get(`/api/chat/${chatId}`, { headers: await getAuthorization() })
//export const apiSendChatMessage = async (chatId: string, newMessage: string): Promise<any> => {};