import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ClipLoader } from 'react-spinners';
import { apiDoctorsList } from '../api/doctor';
import { useNavigate } from 'react-router-dom';

interface Doctor {
  email: string;
  role: string;
  fullname: string;
}

const DoctorList: React.FC = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await apiDoctorsList();
        setDoctors(res.data.doctors);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert('Error loading doctors');
      }
    };

    if (auth?.isAdmin) {
      fetchData();
    }
  }, [auth]);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="flex flex-row justify-end p-3">
        <button
            onClick={context?.logout ?? (() => {})}
            className="text-black p-2 rounded bg-red-400 hover:bg-red-600 transition-all"
          >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6">
            <path fillRule="evenodd" d="M16.5 3.75a1.5 1.5 0 0 1 1.5 1.5v13.5a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5V15a.75.75 0 0 0-1.5 0v3.75a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3V9A.75.75 0 1 0 9 9V5.25a1.5 1.5 0 0 1 1.5-1.5h6Zm-5.03 4.72a.75.75 0 0 0 0 1.06l1.72 1.72H2.25a.75.75 0 0 0 0 1.5h10.94l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 0 0-1.06 0Z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      <div className="max-w-4xl mx-auto bg-white p-8 rounded shadow">
        <div className='flex flex-row mb-5'>
            <h2 className="flex-auto text-2xl font-bold mb-6 text-gray-800">Læknar</h2>
            <button
                onClick={() => navigate('/create-doctor')}
                className="bg-blue-600 text-white px-4 rounded hover:bg-blue-700"
            >
                Skapa nýjan lækni
            </button>
          </div>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <ClipLoader size={50} color={"#3b82f6"} />
          </div>
        ) : (
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-200 text-left font-medium text-gray-700">Fullt nafn</th>
                <th className="py-2 px-4 border-b border-gray-200 text-left font-medium text-gray-700">Email</th>
                <th className="py-2 px-4 border-b border-gray-200 text-left font-medium text-gray-700">Hlutverk</th>
              </tr>
            </thead>
            <tbody>
              {doctors.map((doctor, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b border-gray-200">{doctor.fullname}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{doctor.email}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{doctor.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DoctorList;